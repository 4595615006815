import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";

import Styles from "../../../common/styles/common.css";
import { API_ENDPOINT } from "../../common/constants/common";
import intowowLogo from "../../../common/images/intowow-wand-final.png";
import gLogo from "../../common/images/g-logo.png";

const howToApplyGamAccountFaqUrl =
  "https://intowow.atlassian.net/wiki/spaces/IYBW/pages/670662657";

function Signup(props) {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#011542";
    return () => {
      document.body.style.backgroundColor = null;
    };
  });

  function handleConfirm() {
    setIsConfirmed(!isConfirmed);
  }

  function openTerms() {
    window.location.href = "/#/terms";
  }

  function handleSignup() {
    const redirectUrl = `${window.location.origin}/#/signup`;

    const loginEndpoint = `${API_ENDPOINT}/login?redirect_uri=${encodeURIComponent(
      redirectUrl
    )}`;

    console.log(loginEndpoint);
    console.log("signing up");

    window.location.href = loginEndpoint;
  }

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px"
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "60%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  backgroundColor: "#ff5622",
                  borderRadius: "30px",
                  border: "4px solid black"
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              className="text-center shadow-lg"
              style={{
                borderRadius: "8px",
                padding: "24px",
                background: "#fbfeff"
              }}
            >
              <Card.Body>
                <h1
                  className="mt-2 mb-4 display-4"
                  style={{ fontSize: "42px" }}
                >
                  Sign up to Intowow YieldBooster®
                </h1>
                <p
                  className="text-left"
                  style={{
                    color: "#021d4a",
                    fontSize: "22px",
                    fontWeight: "bold"
                  }}
                >
                  The world’s most powerful Google Ad Manager plug-in, boosts
                  your ad revenue from Google AdSense and Ad Exchange.
                </p>

                <p className="mt-5 mb-5">
                  Already have an account? <a href="/#/login">Login here →</a>
                </p>

                <div>
                  <Card bg="light">
                    <Card.Body className="text-left text-dark">
                      <h4 className="text-dark mb-4 mt-2">
                        Before signing up, please make sure you have the
                        following:
                      </h4>
                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "larger" }}
                      >
                        1. GAM (Google Ad Manager) Account
                      </p>
                      <p>
                        Intowow YieldBooster® works directly with Google Ad
                        Manager, AdSense, and Ad Exchange to boost your Google
                        ad revenue. (
                        <a href={howToApplyGamAccountFaqUrl} target="_blank">
                          <i>How to apply for a GAM account?</i>
                        </a>
                        )
                      </p>

                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "larger" }}
                      >
                        2. Google AdSense Account
                      </p>
                      <p>
                        Make sure that your Google AdSense account is linked to
                        your Google Ad Manager.
                      </p>

                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "larger" }}
                      >
                        3. Google Ad Exchange Account <small>(optional)</small>
                      </p>
                      <p>
                        If you are a direct Google Ad Exchange publisher or work
                        with a Google Ad Exchange network partner, make sure
                        that the corresponding Google Ad Exchange account is
                        linked to your Google Ad Manager network.
                      </p>

                      <div className="text-center mt-5 mb-2">
                        <Form className="mb-4">
                          <Form.Group>
                            <Form.Check
                              id="confirm-prerequisites"
                              type="checkbox"
                              label="I confirm I reached the above requirements"
                              value={isConfirmed}
                              onChange={handleConfirm}
                            />
                          </Form.Group>
                        </Form>

                        <Button
                          variant="light"
                          size="lg"
                          className="shadow bg-white rounded"
                          onClick={handleSignup}
                          disabled={!isConfirmed}
                        >
                          <div className="d-flex align-items-center pl-5 pr-5 pt-2 pb-2">
                            <img src={gLogo} width="24px" height="24px" />
                            <span
                              className="ml-3 text-dark"
                              style={{ fontSize: "18px" }}
                            >
                              Sign up with Google
                            </span>
                          </div>
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <br />
                <div className={Styles.termsWrapper}>
                  <span>{i18n`By continuing, you agree to our`}</span>{" "}
                  <span>
                    <button onClick={openTerms} className={Styles.buttonLink}>
                      {i18n`Service agreement`}
                    </button>
                  </span>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Signup;
