import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { useCookies } from "react-cookie";

import { GET_ASSOCIATED_PUBLISHERS } from "../../base/query/user-info";
import {
  USER_ROLE,
  TRIAL_STATUS,
  SUBSCRIPTION_STATUS,
} from "../constants/common";
import { LoginAsContext } from "./contexts";
import YBLoadingView from "common/components/yb-loading-view";

const COOKIE_NAME = "loginAsPubId";

function LoginAsContextProvider(props) {
  let ignore = false;

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  console.log("checking cookie", cookies.loginAsPubId, cookies);

  // if has cookie, redirect to home

  // // TODO: get from cookie
  const loginAsPubId = cookies.loginAsPubId;

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("hi");
    if (!ignore) {
      getUserInfo();
    }

    return function cleanUp() {
      ignore = true;
    };
  }, []);

  async function getUserInfo() {
    setIsLoading(true);
    const { client } = props;
    try {
      const tempUserInfo = await queryUserInfo(client);
      setUserInfo(tempUserInfo.me);
    } catch (err) {
      // hi
      console.log("getUserInfo Error", err);
    }
    setIsLoading(false);
  }

  function handleLoginAsChange(pubId) {
    console.log("handleLoginAsChange", pubId);
    setCookie(COOKIE_NAME, pubId);
    // getUserInfo();
  }

  return (
    <LoginAsContext.Provider
      value={{ userInfo, loginAsPubId, handleLoginAsChange }}
    >
      {isLoading ? (
        <YBLoadingView />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </LoginAsContext.Provider>
  );
}

async function queryUserInfo(client) {
  try {
    const response = await client.query({
      query: GET_ASSOCIATED_PUBLISHERS,
      fetchPolicy: "network-only",
    });
    const data = await response.data;
    console.log("GET_ASSOCIATED_PUBLISHERS", data);
    return data;
  } catch (err) {
    console.log("Error querying userInfo", err);
    throw "Unable to identify user";
  }
}

function getSubscriptionStatus(tempUserInfo) {
  // SUBSCRIBED, NOT_SUBSCRIBED
  const accountType = _.get(tempUserInfo, "me.publisher.accountType");
  if (accountType === "ACTIVE") {
    return SUBSCRIPTION_STATUS.SUBSCRIBED;
  }

  return SUBSCRIPTION_STATUS.NOT_SUBSCRIBED;
}

function getTrialStatus(tempUserInfo) {
  // TRIAL, TRIAL_EXPIRED
  const accountType = _.get(tempUserInfo, "me.publisher.accountType");
  if (accountType === "TRIAL_EXPIRED") {
    return TRIAL_STATUS.TRIAL_EXPIRED;
  }

  const remainingDays = _.get(
    tempUserInfo,
    "publisherAccountStatus.status.trialStatus.remainingDays"
  );
  if (remainingDays > 0) {
    return TRIAL_STATUS.TRIAL;
  }
}

function getUserRoleFromPublisherType(publisherType) {
  let userRole = null;
  switch (publisherType) {
    case "DIRECT_PUBLISHER": {
      userRole = USER_ROLE.DIRECT_PUBLISHER_ADMIN;
      break;
    }

    case "RESELLER": {
      userRole = USER_ROLE.RESELLER_ADMIN;
      break;
    }

    case "RESELLER_PUBLISHER": {
      userRole = USER_ROLE.MANAGED_PUBLISHER_ADMIN;
      break;
    }

    default: {
      userRole = USER_ROLE.UNRECOGNIZED_USER;
    }
  }

  return userRole;
}

export default withApollo(LoginAsContextProvider);
