import React from "react";
import { withRouter, matchPath } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";

import BaseRouter from "../routes/base-router";
import Header from "../components/header";
import NavigationBar from "../components/navigation-bar";
import YbFooter from "common/components/yb-footer";
import SubscribeCtaHeader from "../components/subscribe-cta-header";

import {
  UserInfoContext,
  PublisherInfoContext,
  LoginAsContext,
} from "../../common/contexts/contexts";
import UserInfoContextProvider from "../../common/contexts/user-info-context-provider";
import PublisherInfoContextProvider from "../../common/contexts/publisher-info-context-provider";
import LoginAsContextProvider from "../../common/contexts/login-as-context-provider";
import LoginAsPublisher from "./login-as-publisher";
import Publishers from "../../publishers/states/publishers";

class LoginAsWrapper extends React.Component {
  constructor(props) {
    super(props);
    document.title = "Login as | Yieldbooster";

    // this.state = {
    //   loginAsPubId: null,
    // };
  }

  componentDidMount() {
    // get login as publishers
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location.pathname !== prevProps.location.pathname) {
  //     // Route changed!

  //     console.log(
  //       "route changed",
  //       prevProps.location.pathname,
  //       this.props.location.pathname
  //     );

  //     const matchingPubId = this.getMatchParams(this.props.location.pathname);
  //     if (matchingPubId !== this.state.matchPubId) {
  //       this.setState({
  //         matchPubId: matchingPubId,
  //       });
  //     }
  //   }
  // }

  render() {
    // const { loginAsPubId } = this.state;
    const client = new ApolloClient({
      uri: process.env.INTOWOW_GRAPHQL_ENDPOINT + "/graphql",
      headers: {
        "Access-Control-Request-Headers": "content-type, x-in2w-assume-pub",
        // "Access-Control-Request-Method": "GET",
        "content-type": "text/plain",
        "x-in2w-assume-pub": 320,
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods":
        //   "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      },
      credentials: "include",
      fetchOptions: {
        useGETForQueries: true,
      },
    });
    return (
      <div>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            {/* <Publishers></Publishers> */}
            <LoginAsContextProvider>
              <LoginAsContext.Consumer>
                {({ userInfo, loginAsPubId, handleLoginAsChange }) => {
                  return (
                    <div>
                      <LoginAsPublisher
                        loginAsPubId={loginAsPubId}
                        userInfo={userInfo}
                        handleLoginAsChange={handleLoginAsChange}
                      ></LoginAsPublisher>
                    </div>
                  );
                  // return this.props.children;
                }}
              </LoginAsContext.Consumer>
            </LoginAsContextProvider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </div>
    );
  }
}

export default LoginAsWrapper;
