import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import intowowLogo from "../../../common/images/intowow-wand-final.png";
import InviteSignup from "./invite-signup";
import RegisterByInvitation from "./register-by-invitation";
import { Card } from "react-bootstrap";
import Styles from "../styles/login-as.css";

function LoginAsPublisher(props) {
  const [userInfo, setUserInfo] = useState(null);

  // const onSignup = props.onSignup;
  useEffect(() => {
    console.log(props);
    document.body.style.backgroundColor = "#161A30";

    const userInfo = props.userInfo;
    setUserInfo(userInfo);

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  function handlePublisherClicked(pubId) {
    props.handleLoginAsChange(pubId);
  }

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "120px",
        }}
      >
        <div className="d-flex justify-content-center align-self-center">
          <div style={{ width: "60%" }}>
            <div className="d-flex justify-content-center pb-4">
              <div
                style={{
                  // background: "linear-gradient(to bottom, #f83600, #fe8c00)",
                  // background: "linear-gradient(to top, #2B32B2, #1488CC)",
                  backgroundColor: "#ff5622",
                  borderRadius: "30px",
                  border: "4px solid black",
                }}
              >
                <img src={intowowLogo} width="52px" />
              </div>
            </div>

            <Card
              className="text-center shadow-lg"
              style={{
                borderRadius: "8px",
                padding: "24px",
                background: "#fbfeff",
              }}
            >
              <Card.Body>
                <h1 className="mt-2 mb-4">
                  Choose a publisher {props.loginAsPubId}
                </h1>

                <div className="d-flex justify-content-center align-self-center">
                  <div className={Styles["login-as-list-wrapper"]}>
                    <div className={Styles["login-as-list-header"]}>
                      Login to Yieldbooster as
                    </div>
                    <div className={Styles["login-as-list"]}>
                      {userInfo &&
                        userInfo.associatedPublishers.map((pub) => {
                          return (
                            <div
                              key={pub.publisherId}
                              className={Styles["login-as-list-item"]}
                              onClick={() =>
                                handlePublisherClicked(pub.publisherId)
                              }
                            >
                              {pub.publisherId} {pub.name}
                              {pub.reseller ? (
                                <div style={{ fontSize: "16px" }}>
                                  ({pub.reseller.name})
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginAsPublisher;
