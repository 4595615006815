import React from "react";
import { Query } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import YbLoading from "common/components/yb-loading";

import { PUBLISHER_ACCOUNT_STATUS } from "common/query/publisher";

class YbPublisherAccountStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Query
        query={PUBLISHER_ACCOUNT_STATUS}
        variables={{ pubId: this.props.publisherId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          var publisherAccountStatus = null;
          var status = <span />;

          if (loading) {
            return <YbLoading isWhiteText={true} />;
          }

          if (error) {
            return (
              <span className={CommonStyle.textDanger}>
                {i18n`ERROR_LOADING_ACCOUNT_STATUS_ELLIPSIS`}
              </span>
            );
          }

          if (data && !publisherAccountStatus) {
            publisherAccountStatus = data.publisherAccountStatus;
          }

          switch (publisherAccountStatus.publisher.accountType) {
            case "TRIAL":
              var trial = data.accountConfig.config.resourceConfig.trial;

              if (trial.enabled) {
                if (
                  trial.period !== -1 &&
                  publisherAccountStatus.status.trialStatus
                ) {
                  status = (
                    <span className={CommonStyle.textWarning}>
                      {publisherAccountStatus.status.trialStatus.remainingDays}{" "}
                      {i18n`DAY_S_REMAINING`}
                    </span>
                  );
                } else {
                  status = (
                    <span
                      className={CommonStyle.textWarning}
                    >{i18n`TRIAL_PERIOD`}</span>
                  );
                }
              }
              break;
            case "TRIAL_EXPIRED":
              break;
            case "ACTIVE":
              var trial = data.accountConfig.config.resourceConfig.trial;

              if (trial.enabled) {
                if (
                  trial.period !== -1 &&
                  publisherAccountStatus.status.trialStatus
                ) {
                  status = (
                    <span className={CommonStyle.textWarning}>
                      {publisherAccountStatus.status.trialStatus.remainingDays}{" "}
                      {i18n`DAY_S_REMAINING`}
                    </span>
                  );
                }
              }
              break;
            case "INACTIVE":
              status = (
                <span className={CommonStyle.textDanger}>Inactive Account</span>
              );
              break;
            case "CHARGE_FAILED":
              status = (
                <span className={CommonStyle.textDanger}>
                  Your acccount has been suspended
                </span>
              );
              break;
          }

          return (
            <span className={CommonStyle.publisherAccountWrapper}>
              {status}
            </span>
          );
        }}
      </Query>
    );
  }
}

module.exports = YbPublisherAccountStatus;
